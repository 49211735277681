// @mui
import { styled } from '@mui/material/styles'
import { Box, Container, Typography } from '@mui/material'
// components
import Page from '../components/Page'
// assets
import MobilioTruck from '../assets/images/mobilio_truck.svg'
import Image from '../components/Image'
import useLocales from '../hooks/useLocales'
import { Trans } from 'react-i18next'
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => {
  return {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(10),
  }
})

// ----------------------------------------------------------------------

type ComingSoonProps = {
  title?: string
}

export default function ComingSoon({ title }: ComingSoonProps) {
  const { translate: t } = useLocales()

  return (
    <Page title={t(title || 'Coming Soon')} sx={{ height: 1 }}>
      <RootStyle>
        <Container>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <Typography variant="h3" paragraph>
              {t('coming_soon.title')}
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              <Trans i18nKey="coming_soon.description">
                Cliquez
                <a
                  href="https://forms.office.com/e/qpm8gt29sZ"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  ici
                </a>
                pour nous indiquer les fonctionnalités auxquelles vous souhaiteriez accéder en
                priorité.
              </Trans>
            </Typography>
            <Image src={MobilioTruck} sx={{ my: 10, height: 240 }} />
          </Box>
        </Container>
      </RootStyle>
    </Page>
  )
}
