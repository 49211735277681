// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`
}

const ROOTS_HOME = '/'
const ROOTS_AUTH = '/auth'
export const ROOTS_DASHBOARD = '/dashboard'

// ----------------------------------------------------------------------

export const PATH_HOME = {
  homePage: ROOTS_HOME,
}

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
}

export const PATH_PAGE = {
  legalMentions: '/legal-mentions',
  tutoriel: '/tutoriel',
  privacy: '/privacy',
  cgvu: '/cgvu',
  aboutUs: '/about-us',
  contactUs: '/contact-us',
  subscribe: '/subscribe',
  documentation: '/documentation',
  faq: '/faq',
  maintenance: '/maintenance',
  page404: '/404',
  page500: '/500',
  components: '/components',
}

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    quotes: path(ROOTS_DASHBOARD, '/quotes'),
    quoteAdd: path(ROOTS_DASHBOARD, '/quotes/add/:type'),
    quoteEdit: path(ROOTS_DASHBOARD, '/quotes/edit'),
    configuration: path(ROOTS_DASHBOARD, '/configuration'),
    configurationEdit: path(ROOTS_DASHBOARD, '/configuration'),
    pathOptimizationsForQuote: path(ROOTS_DASHBOARD, '/path-optimization/quote/:id'),
    pathOptimizationsSchema: path(ROOTS_DASHBOARD, '/path-optimization/schema'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account'),
  },
  dashboard : path(ROOTS_DASHBOARD, '/home'),
  planning: path(ROOTS_DASHBOARD, '/planning'),
  routeOptimization: path(ROOTS_DASHBOARD, '/route-optimization'),
  parkingRequest: path(ROOTS_DASHBOARD, '/parking-request'),
  marketPlace: path(ROOTS_DASHBOARD, '/market-place'),
}

export const PATH_QUOTE = {
  view: {
    root: path(ROOTS_DASHBOARD, '/quotes/view'),
    view: path(ROOTS_DASHBOARD, '/quotes/view/:id'),
  },
  edit: {
    root: path(ROOTS_DASHBOARD, '/quotes/editView'),
    view: path(ROOTS_DASHBOARD, '/quotes/editView/:id'),
  },
}

export const PATH_DOCS = '/tutoriel'
export const PATH_DOCUMENTATION = '/documentation'
export const PATH_FAQ = '/faq'
export const PATH_CONTACT = '/contact-us'

export const PATH_SUBSCRIPTION = '/subscribe'
